import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiConstants, BASE_URL } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";
import axios from "axios";

// Async thunk for auth
export const sendOtp = createAsyncThunk('auth/sendotp', async ({ phoneNumber, countryCode, portal }, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${BASE_URL}${ApiConstants.SENDOTP}`, {
            country_code: countryCode,
            phone_no: phoneNumber,
            portal: portal
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const verifyOtp = createAsyncThunk('auth/verifyOtp', async ({ countryCode, phoneNumber, otpId, otp }, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.LOGIN}`, {
            country_code: countryCode,
            phone_no: phoneNumber,
            otp_id: otpId,
            otp,
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

export const resendOtp = createAsyncThunk('auth/resendOtp', async ({ otpId }) => {
    const response = await apiClient.post(`${BASE_URL}${ApiConstants.RESENDOTP}`, {
        otp_id: otpId,
    });
    return response.data;
});

export const refreshAccessToken = createAsyncThunk(
    'auth/refreshAccessToken',
    async (_, { rejectWithValue }) => {
        try {
            const refresh_token = localStorage.getItem('refresh_token');
            if (!refresh_token) {
                throw new Error("No refresh token available");
            }
            const response = await apiClient.post(ApiConstants.REFRESHTOKEN, { refresh_token });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data || 'Failed to refresh token');
        }
    }
);

// registerOTP 
export const registerOTP = createAsyncThunk('auth/registerOTP', async ({ registerData }, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.REGISTER_OTP}`, registerData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// Verify otp
export const verifyRegisterOtp = createAsyncThunk('auth/verifyRegisterOtp', async ({ registerVerifyData }, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.REGISTER}`, registerVerifyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// Logout
export const logout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.delete(`${BASE_URL}${ApiConstants.LOGOUT}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// Slice for auth
const authSlice = createSlice({
    name: 'auth',
    initialState: {
        otpId: null,
        status: 'idle',
        error: null,
        data: null,
        access_token: null,
        refresh_token: null,
        user: null,
        isAuthenticated: false
    },
    reducers: {
        clearToken(state) {
            state.access_token = null;
            state.refresh_token = null;
            state.isAuthenticated = false;
            state.user = null;
            if (localStorage.getItem("access_token")) {
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
            }
        },
        setToken(state, action) {
            state.access_token = action.payload.data.access_token;
            state.refresh_token = action.payload.data.refresh_token;
            state.isAuthenticated = true;
            localStorage.setItem("access_token", state.access_token);
            localStorage.setItem("refresh_token", state.refresh_token);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendOtp.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(sendOtp.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.otpId = action.payload.data.otp_id;
                state.error = null;
            })
            .addCase(sendOtp.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action?.payload?.error?.message;
            })
            .addCase(verifyOtp.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                if (action.payload.success) {
                    state.data = action.payload.data.user_details;
                    state.access_token = action.payload.data.access_token;
                    state.refresh_token = action.payload.data.refresh_token;
                    localStorage.setItem('access_token', state.access_token);
                    localStorage.setItem('refresh_token', state.refresh_token);
                }
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })
            .addCase(resendOtp.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(resendOtp.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
            })
            .addCase(resendOtp.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(refreshAccessToken.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(refreshAccessToken.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                state.access_token = action.payload.data.access_token;
                localStorage.setItem("access_token", state.access_token);
            })
            .addCase(refreshAccessToken.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(registerOTP.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(registerOTP.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                if (action.payload.success) {
                    state.data = action.payload.data;
                }
            })
            .addCase(registerOTP.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })
    }
});

export const { clearToken, setToken } = authSlice.actions

export default authSlice.reducer;