import React from 'react'
import "./OurClients.scss"
import { image } from "../utils/common/image"
import { Carousel } from 'antd';

const OurClients = () => {
    return (

        <div className='ourClients'>
            <div className='ourClients_container'>
                <div className='backgroundAnimation'>
                    <img src={image.BackgroundAnimation} />
                </div>
                <div className='ourClients_main'>
                    <h1 className='ourClients_heading'>Our Clients</h1>
                    <p className='ourClients_description'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                    </p>
                </div>
                <div className='clientList'>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client1} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client4} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client2} />

                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client4} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client4} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client3} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client4} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client2} />
                    </div>

                </div>


                <div className='semiCircle2'>
                    <img src={image.SemiCircle} />
                </div>
            </div>
        </div>
    )
}

export default OurClients
