import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiConstants, BASE_URL } from '../../app/utils/apis';
import { apiClient } from '../../app/helper/axiosHelper';

export const uploadFile = createAsyncThunk(
    'template/uploadFile',
    async ({ formData, fromRegister }, { rejectWithValue }) => {
        try {
            let url = `${BASE_URL}${ApiConstants.UPLOADFILE}`;
            if (fromRegister) {
                url += "?from_register=1";
            }
            const response = await apiClient.post(url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getCategories = createAsyncThunk('template/getCategories', async () => {
    const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETCATEGORIES}`);
    return response.data.data;
}
);

export const screenAvailable = createAsyncThunk('template/screenAvailable', async (screenData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.SCREENAVAILABLE}`, screenData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const dateAvailable = createAsyncThunk('template/dateAvailable', async (dateData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.DATEAVAILABLE}`, dateData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const postAds = createAsyncThunk('template/postAds', async (postData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.POSTADS}`, postData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

const templateSlice = createSlice({
    name: 'template',
    initialState: {
        otpId: null,
        status: 'idle',
        error: null,
        data: null,
        access_token: null,
        refresh_token: null,
        user: null,
        isAuthenticated: false,
        OrderDetails: {},
    },
    reducers: {
        clearOrderDetails(state) {
            state.OrderDetails = {};
        },
    },
    extraReducers: (builder) => {
        builder
            // Upload file API
            .addCase(uploadFile.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                if (action.payload.success) {
                    state.data = action.payload.data;
                }
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })

            // Get categories API
            .addCase(getCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            // Screen available API
            .addCase(screenAvailable.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(screenAvailable.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                state.data = action.payload
            })
            .addCase(screenAvailable.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })

            // Date Available Api
            .addCase(dateAvailable.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(dateAvailable.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(dateAvailable.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            // Add Post ads
            .addCase(postAds.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postAds.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                state.OrderDetails = action.payload.data
            })
            .addCase(postAds.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            });
    },
});
export const { clearOrderDetails } = templateSlice.actions;
export const selectTemplateStatus = (state) => state.template?.status;
export const selectTemplateError = (state) => state.template?.error;

export default templateSlice.reducer;
