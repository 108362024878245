import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { DAYS_OPTION } from '../../../app/utils/constants/option';

import './SelectInput.scss'

const SelectInput = (props) => {
    const { label, selected, placeholder, type, manualOptions, className, classNameInput, onChange, disabled } = props;
    const [options, setOptions] = useState([]);
    const [selecteOption, setSelecteOption] = useState();

    useEffect(() => {
        switch (type) {
            case 'discount':
                setSelecteOption(DAYS_OPTION.find(ele => ele.value === selected));
                break;
            default:
                setOptions(manualOptions);
                break;
        }

    }, [selected, manualOptions]);

    useEffect(() => {
        switch (type) {
            case 'discount':
                setOptions(DAYS_OPTION);
                break;
            default:
                setOptions(manualOptions);
                break;
        }
    }, [manualOptions]);

    return (
        <div className={`${className} select-input`}>
            <div className='form-label'>{label}</div>
            <Select
                isDisabled={disabled}
                value={selecteOption}
                placeholder={placeholder}
                options={options}
                onChange={onChange}
                className={classNameInput}
                defaultValue={options[1]}
                isMulti={(type === 'projectCategory') ? true : false}

                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        // borderRadius: state.menuIsOpen ? '18px 18px 0 0' : '33px',
                        borderRadius: '0.375rem',
                        boxShadow: 'none',
                        background: state.isFocused ? 'linear-gradient(white, white) padding-box,linear-gradient(95.39deg, #B2D236 9.4%, #2BB24B 51.68%, #01A8AC 96.92%) border-box' : props.disabled ? '#c0cece6e' : '',
                        border: state.isFocused ? '1.5px solid #0c4a6e' : props.emptyInput ? '1px solid red !important' : '',
                        // borderBottom: state.menuIsOpen ? 'none' : '',
                        "&:hover": {
                            background: props.disabled ? '#d1d5db' : 'linear-gradient(white, white) padding-box,linear-gradient(95.39deg, #B2D236 9.4%, #2BB24B 51.68%, #01A8AC 96.92%) border-box',
                            border: '2px solid #0c4a6e'
                        },
                        minHeight: '44px',
                        cursor: props.disabled ? 'no-drop' : 'pointer',
                    }),
                    // multiValue: (baseStyles, state) => ({
                    //     ...baseStyles,
                    //     backgroundColor: 'transparent',
                    //     borderRadius: '12px',
                    //     border: '1px solid #c0cece',
                    //     marginTop: '2.5px',
                    //     marginLeft: '5px',
                    //     borderColor: '#01a8ac',
                    //     cursor: props.disabled ? 'no-drop' : 'default',
                    //     "&:hover": {
                    //         background: props.disabled ? '#c0cece6e' : 'linear-gradient(white, white) padding-box,linear-gradient(95.39deg, #B2D236 9.4%, #2BB24B 51.68%, #01A8AC 96.92%) border-box',
                    //         border: '1px solid transparent'
                    //     },
                    // })
                }} />
        </div>
    )
}

export default SelectInput
