import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { image } from '../../../app/utils/common/image';
import { getAllProfile, getUserProfile, setProfileData, setCurrentPage, setSelectedProfileId } from '../../../redux/Slices/agencyProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import './ProfileDropDown.scss'
import Loader from '../Loader/Loader';

const ProfileDropDown = ({ setOpenProfileDrpDwn }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: agenciesProfile, pagination, status, selectedProfileId } = useSelector(getAllProfile);
    const hasMore = pagination.currentPage < pagination.totalPages;

    useEffect(() => {
        if (pagination.currentPage > 1) {
            dispatch(getUserProfile({ page: pagination.currentPage }));
        }
    }, [dispatch, pagination.currentPage]);

    const fetchMoreProfiles = () => {
        if (hasMore) {
            dispatch(setCurrentPage(pagination.currentPage + 1));
        }
    };

    const handlePreviousClick = () => {
        if (pagination.currentPage > 1) {
            dispatch(getUserProfile(pagination.currentPage - 1));
        }
    }

    const handleProfileClick = (profile) => {
        dispatch(setSelectedProfileId(profile.id));
        dispatch(setProfileData({ name: profile.name, id: profile.id, phone_no: profile.phone_no, shop_name: profile?.shop_name, profile_logo: profile?.profile_logo }));
        navigate('/myAds');
        setOpenProfileDrpDwn(false)
    };
    return (
        <>
            <div className='relative'>
                <div className='profile-dropdown-container'>
                    {pagination.currentPage > 1 ?
                        <img src={image.BACKICON} alt='BackArrow' className='backArrow' onClick={handlePreviousClick} />
                        : ""}
                    {status === "loading" ? <Loader height="h-[20vh]" paddingRight="pr-[3rem]" /> :
                        <>
                            {agenciesProfile.length > 0 ? (
                                agenciesProfile.map((profile) => (
                                    <div
                                        key={profile.id}
                                        className={profile.id === selectedProfileId ? 'profile-dropdownSelected' : 'profile-dropdown'}
                                        onClick={() => handleProfileClick(profile)}
                                    >
                                        <img
                                            src={profile.profile_logo ? profile.profile_logo : image.IMG_DEFAULT_PROFILE}
                                            alt={profile.name}
                                            className='w-[40px] h-[40px] rounded-[50%]'
                                        />
                                        <div>{profile.name}</div>
                                    </div>
                                ))
                            ) : (
                                <div className='no-profiles-message'>
                                    No profiles available
                                </div>
                            )}

                            {hasMore && (
                                <button onClick={fetchMoreProfiles} className='load-more-button'>
                                    {/* <Loader type="TailSpin" color="#10b981" height={15} width={15} /> */}
                                    <div className='loadMoreContainer'>
                                        <div>
                                            <p>Load More... </p>
                                        </div>
                                        <div>
                                            <img src={image.RIGHTARROW} alt='RightArrow' className='rightArrow' />
                                        </div>
                                    </div>
                                </button>
                            )}
                        </>
                    }
                </div>
            </div >
        </>
    )
}

export default ProfileDropDown