import React, { useEffect, useRef, useState } from 'react'
import * as fabric from 'fabric'; // v6
import { TemplateSettingPanel } from '../../../molecules/TemplateSettingPanel/TemplateSettingPanel';
import "./CreateTemplateModalDetails.scss"
import { useDispatch } from 'react-redux';
import { uploadFile } from '../../../../redux/Slices/templateSlice';
import Button from '../../../atoms/Button/Button';
import Loader from '../../../atoms/Loader/Loader';


export const CreateTemplateModalDetails = ({ setTemplateImageUrl, setCreateTemplateOpen }) => {
    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
    const [objectDetails, setObjectDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        const canvasInstance = new fabric.Canvas(canvasRef.current);
        setCanvas(canvasInstance);

        // if (!canvasRef.current) return;
        const resizeObserver = new ResizeObserver(() => {

        });
        resizeObserver.observe(canvasRef.current);

        // Listen for selection events to toggle Delete button state
        canvasInstance.on('selection:created', () => {
            setIsDeleteButtonEnabled(true);
            updateObjectDetails(canvasInstance);

        });

        canvasInstance.on('selection:updated', () => {
            setIsDeleteButtonEnabled(true);
            updateObjectDetails(canvasInstance);

        });

        canvasInstance.on('selection:cleared', () => {
            setIsDeleteButtonEnabled(false);
            updateObjectDetails(canvasInstance);

        });
        canvasInstance.on('object:modified', () => {
            updateObjectDetails(canvasInstance);
        });

        canvasInstance.on('object:scaled', () => {
            updateObjectDetails(canvasInstance);
        });

        canvasInstance.on('object:moved', () => {
            updateObjectDetails(canvasInstance);
        });

        canvasInstance.on('object:rotated', () => {
            updateObjectDetails(canvasInstance);
        });

        return () => {
            canvasInstance.dispose();
            resizeObserver.disconnect(); // clean up 
        };
    }, [])


    // Function to update object details
    const updateObjectDetails = (canvas) => {
        const objects = canvas.getObjects().map((obj) => ({
            type: obj.type,
            left: obj.left,
            top: obj.top,
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
            width: obj.width * obj.scaleX,
            height: obj.height * obj.scaleY,
            fill: obj.fill,
            stroke: obj.stroke,
            strokeWidth: obj.strokeWidth,
            radius: obj.radius,
            angle: obj.angle,
            text: obj.text,
            fontSize: obj.fontSize,
            fontFamily: obj.fontFamily,
            backgroundColor: obj.backgroundColor,
            overlayColor: obj.overlayColor,
            selectionBorderColor: obj.selectionBorderColor,
            selectionColor: obj.selectionColor,
            fill: obj.fill,
            shadow: obj.shadow,
            opacity: obj.opacity,
            originX: obj.originX,
            originY: obj.originY,
            cornerSize: obj.cornerSize,
        }));
        setObjectDetails(objects);
    };

    //Add Text
    const addText = () => {
        const canvasWidth = canvas.getWidth();
        const canvasHeight = canvas.getHeight();

        const text = new fabric.IText('Your paragraph text', {
            fontFamily: 'arial black',
            // left: 100,
            // // top: 100,
            // left: canvasWidth / 2,    
            // top: canvasHeight / 2,   
            // originX: 'center',
            fontSize: 24,
            fontFamily: 'arial black',
            fill: "black",
            // stroke: 'purple'
        });

        // Add the text to the canvas
        canvas.add(text);
    }

    //Add Rectangle
    const addRectangle = () => {
        const canvasWidth = canvas.getWidth();
        const canvasHeight = canvas.getHeight();
        const rect = new fabric.Rect({
            //Create rectangle object
            // left: Math.random() * 400,
            // top: Math.random() * 400,
            left: canvasWidth / 2,
            top: canvasHeight / 2,
            originX: 'center',
            originY: 'center',

            fill: "black",
            width: 50,
            height: 50,
            stroke: 'black',
            // strokeWidth: 3,

        });
        canvas.add(rect);
    }

    const addCircle = () => {
        const canvasWidth = canvas.getWidth();
        const canvasHeight = canvas.getHeight();

        const circle = new fabric.Circle({

            left: canvasWidth / 2,
            top: canvasHeight / 2,
            originX: 'center',
            radius: 50,
            stroke: 'black',
            fill: "black"
        });
        canvas.add(circle);
    }

    const addTriangle = () => {
        const canvasWidth = canvas.getWidth();
        const canvasHeight = canvas.getHeight();
        const triangle = new fabric.Triangle({
            // left: 55,
            // top: 60,
            left: canvasWidth / 2,
            top: canvasHeight / 2,
            originX: 'center',
            originY: 'center',
            width: 50,
            // width: canvasRef.current.offsetWidth,
            // height: canvasRef.current.offsetHeight,
            height: 60,
            fill: "black",
        });
        // Add it to the canvas
        canvas.add(triangle);

    }

    // Delete selected object
    const deleteSelectedObject = () => {
        const activeObjects = canvas.getActiveObjects();
        if (activeObjects.length) {
            if (activeObjects.length !== 0) {
            }

            activeObjects.forEach((obj) => canvas.remove(obj));
            canvas.discardActiveObject(); // Deselect all objects
            canvas.requestRenderAll(); // Rerender canvas
            setIsDeleteButtonEnabled(false);
        }
    };

    // Convert base64 image into Url
    const dataURLToFile = (dataURL, filename) => {
        const [header, data] = dataURL.split(',');
        const mime = header.match(/:(.*?);/)[1];
        const binary = atob(data);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        const blob = new Blob([new Uint8Array(array)], { type: mime });
        return new File([blob], filename, { type: mime });
    };

    //convert base64 to file
    const base64ToFile = (base64Stringinput, fileName) => {
        let base64String = base64Stringinput.toString();
        const byteString = atob(base64String.split(',')[1]); // Decoding the Base64 string
        const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        const file = new File([blob], fileName, { type: mimeString });
        return file;
    }

    // Handle to submit the  template
    const handleSaveTemplate = async () => {

        setIsLoading(true);
        console.log('objectDetails::objectDetails=> ', objectDetails)
        const canvasData = canvas.toJSON(); // Get all object data from the canvas
        console.log('canvasData', canvasData)
        const filterCanvasData = canvasData.objects.filter((item) => {
            if (item.type === "Image") {
                return item;
            }
        })
        console.log('Saved Template Data:', filterCanvasData);

        const getImgeSrcFromCanvaData = filterCanvasData.map((item, index) => {
            return item.src;
        })

        console.log('getImgeSrcFromCanvaData', getImgeSrcFromCanvaData);

        const uploadedUrls = [];

        const templateImage = canvas.toDataURL(); // Generates a PNG image data URL
        console.log('templateImage----', templateImage)
        const fileImage = dataURLToFile(templateImage, 'template.png');
        try {
            console.log('getImgeSrcFromCanvaData.length', getImgeSrcFromCanvaData.length)
            for (let i = 0; i < getImgeSrcFromCanvaData.length; i++) {
                const base64Image = getImgeSrcFromCanvaData[i];
                const fileName = `selectedImage_${i + 1}.png`;
                console.log('fileName', fileName)

                const file = base64ToFile(base64Image, fileName);
                console.log(' upper Uploading file', file)
                console.log(`Uploading image ${i + 1}:`, file);

                const formData = new FormData();
                formData.append('file', file);
                formData.append('bucket', 'templates');

                // Upload the image file
                const uploadResponse = await dispatch(uploadFile({ formData, fromRegister: false})).unwrap();
                const url = uploadResponse?.data?.imageUrl;

                console.log(`Uploaded image ${i + 1} URL:`, url);

                // Add the URL to the uploadedUrls array
                uploadedUrls.push(url);

 
            }

            console.log('Uploaded URLs:', uploadedUrls);

            // Update the original canvas data with the uploaded URLs
            const updatedCanvasData = { ...canvasData };
            updatedCanvasData.objects = updatedCanvasData.objects.map((item, index) => {
                if (item.type === 'Image') {
                    const newUrl = uploadedUrls[index];
                    item.src = newUrl;
                }
                return item;
            });
            console.log('Updated canvas data:', updatedCanvasData);

            setIsLoading(false);
            setCreateTemplateOpen(false);

        } catch (error) {
            console.error('Error uploading image:', error);
            setIsLoading(false);
        }

    };




    const handleImageUploadCanva = async (event) => {
        const file = event.target.files[0];

        console.log('file000000: ', file)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('bucket', 'uploadCanvaImageInTemplate');

        // Dispatch uploadFile action with the selected image URL
        const uploadResponse = await dispatch(uploadFile({ formData, fromRegister: false})).unwrap();
        const AddImageURL = uploadResponse?.data?.imageUrl;
        console.log('AddImageURL~~~~~: ', AddImageURL)

        console.log('file', file)
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const image = new Image();
                console.log('imageimageimageimageimageimage=>', image)
                image.src = e.target.result;
                console.log('image.src:: ', image.src)
                image.onload = function () {
                    const canvasWidth = canvas.getWidth();
                    const canvasHeight = canvas.getHeight();
                    const fabricImage = new fabric.Image(image, {
                        left: canvasWidth / 2,
                        top: canvasHeight / 2,
                        originX: 'center',
                        originY: 'center',
                        scaleX: 0.5,
                        scaleY: 0.5,
                        // src:{file},
                    });

                    canvas.add(fabricImage);
                    canvas.renderAll();
                };
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            {isLoading ? <div>
                {isLoading && <Loader />}
            </div> :
                <div className='template-main'>
                    <div className='template-canva_Main'>
                        <div className="template-leftSide">
                            {/* <Button buttonTitle="Save" onClick={handleSaveTemplate} background="bg-[--themeColor]" className={'save-button'} /> */}


                            <h2 className="leftSide-title" background="bg-[--themeColor]" onClick={addText}>TEXT</h2>
                            <h2 className="leftSide-title" onClick={addRectangle}>Rectangle</h2>
                            <h2 className="leftSide-title" onClick={addCircle}>Circle</h2>
                            <h2 className="leftSide-title" onClick={addTriangle}>Triangle</h2>
                            <input
                                className="leftSide-title"
                                type="file"
                                accept="image/*"
                                onChange={handleImageUploadCanva}
                                style={{ display: 'none' }}
                                id="image-upload"
                            />
                            <label htmlFor="image-upload" className="image-upload-label">
                                Add Image📤
                            </label>

                        </div>

                        <div className="template-rightSide">

                            <div>
                                <h4
                                    className={`deleteButton ${isDeleteButtonEnabled ? 'deleteButtonEnable' : 'deleteButtonDisable'}`}
                                    onClick={isDeleteButtonEnabled ? deleteSelectedObject : null}  >  Delete✂️
                                </h4>
                            </div>
                            <div className='responsive-canvas'>
                                <canvas
                                    class="canvas-container"
                                    // className="responsive-canvas"
                                    style={{ border: "2px dotted grey", borderRadius: "5px" }}
                                    width={900} height={400}
                                    ref={canvasRef}
                                />
                                <TemplateSettingPanel canvas={canvas} />

                            </div>
                        </div>
                    </div>

                    <div className='footer-buttons'>
                        <Button buttonTitle="Cancel" onClick={() => { setCreateTemplateOpen(false) }} background="bg-[#fff]" className={'denied-button'} />
                        <Button buttonTitle="Save" onClick={handleSaveTemplate} background="bg-[--themeColor]" className={'save-button'} />
                    </div>

                </div>}



        </div>
    )
}
