export const image = {
    DefaultImage: require('../../../assets/images/defaultImage.jpg'),
    LogoHere: require('../../../assets/images/cosmetic.png'),
    IMG_CLOSE: require('../../../assets/images/close.png'),
    IMG_UPLOAD: require('../../../assets/images/upload-image-icon.png'),
    IMG_LOGO: require('../../../assets/images/logo-ad.jpg'),
    IMG_DEFAULT_PROFILE: require('../../../assets/images/Default-profile-image.png'),
    MYADS: require('../../../assets/images/Gigi logo.jpg'),
    DROPDOWNICON: require('../../../assets/images/dropdownIcon.png'),
    BACKICON: require('../../../assets/images/Back-Icon.png'),
    RIGHTARROW: require('../../../assets/images/Right Arrow.png'),

    HOME: require('../../../assets/images/home.png'),
    ADDETAILS: require('../../../assets/images/AdDetails.png'),
    SCHEDULE: require('../../../assets/images/schedule.png'),
    PAYMENT: require('../../../assets/images/payment.png'),
    MYADS: require('../../../assets/images/myAds.png'),

    ADDPROFILE: require('../../../assets/images/profile.png'),
    VIEWADS: require('../../../assets/images/Ads View.png'),
    CREATEAD: require('../../../assets/images/Create Ad.png'),
    ADDETAILSDAYS: require('../../../assets/images/ad details days.png'),
    AGENCYPAY: require('../../../assets/images/agency pay.png'),
}