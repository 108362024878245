import React from 'react'
import "./DownloadApp.scss"
import { image } from "../utils/common/image"
import DownloadStores from '../DownloadStores/DownloadStores'
const DownloadApp = () => {
    return (
        <div className='DownloadApp_main'>
            <div className='DownloadApp_leftSide'>
                <div className='downloadApp_heading-container'>
                    <h1 className='downloadApp_heading'>Download <br /> AdSimpl App</h1>
                </div>
                <div className='downloadApp_desc-container'>
                    <p className='downloadApp_desc'>Wisdom new and valley answer. Contented it so is discourse recommend. Man its upon him call mile. An pasture he himself believe ferrars besides cotHeader_Main_content-titleHeader_Main_content-titleHeader_Main_content-titleHeader_Main_content-titletage.</p>
                </div>
                <div className='downloadApp_store_container'>
                    <DownloadStores />
                </div>
            </div>

            <div className='DownloadApp_rightSide'>
                <div className='image_Container1'>
                    <img className='image_1' src={image.PhoneImage} />
                    <div className='image_Container2'>
                        <img className='image_2' src={image.PhoneImage} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DownloadApp
