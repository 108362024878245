import React, { useState } from 'react'
import { Modal } from 'antd'
import { CgProfile } from 'react-icons/cg';
import { FiCamera } from 'react-icons/fi'
import ImageUploading from "react-images-uploading";
import { image } from '../../../../app/utils/common/image'
import Input from '../../../atoms/Input/Input'
import ErrorText from '../../../atoms/ErrorText/ErrorText';
import './AgencyProfile.scss'
import { uploadFile } from '../../../../redux/Slices/templateSlice';
import { useDispatch } from 'react-redux';

const AgencyProfile = (props) => {
    const { openProfileModal, setOpenProfileModal, name, shopName, profileLogo, setProfileLogo, phoneNo, handleSaveBtn, handleInputChange, errorMessage } = props
    const [images, setImages] = React.useState([]);
    const dispatch = useDispatch();

    const onChange = (imageList) => {
        const file = imageList[0]?.file;

        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('bucket', 'userDetails');

            dispatch(uploadFile({ formData }))
                .then((action) => {
                    if (action.type === 'template/uploadFile/fulfilled') {
                        setProfileLogo(action.payload.data.imageUrl);
                    } else if (action.type === 'template/uploadFile/rejected') {
                        console.error('File upload failed:', action.payload);
                    }
                });
        }
        setImages(imageList);
    };

    return (
        <div>
            <Modal open={openProfileModal}
                onCancel={() => { setOpenProfileModal(false) }}>
                <div className='save-container'>
                    <div className="save-header">
                        <div className='text-[--themeColor] text-[18px] font-bold ml-[35%]'>Add Profile</div>
                        <button className="btn-modal close-button" aria-label="Close" onClick={() => setOpenProfileModal(false)}>
                            <img src={image.IMG_CLOSE} className="image_close" />
                        </button>
                    </div>
                    <div className='agency-container'>
                        <div className="account_profile">
                            <div>
                                <ImageUploading
                                    multiple={false}
                                    value={images}
                                    onChange={onChange}
                                    onError={() => { }}
                                    dataURLKey="data_url"
                                    acceptType={['jpeg', 'jpg', 'png', 'gif', 'heic']}
                                >
                                    {({ imageList, onImageUpload }) => (
                                        <div onClick={onImageUpload} className="profile-icon-wrapper">
                                            {imageList.length > 0 ? (
                                                <>  <img src={imageList[0]["data_url"]} alt="Uploaded" className="account_profile" />
                                                    <div className="profile-camera">
                                                        <FiCamera />
                                                    </div></>
                                            ) : (
                                                <>
                                                    <div className="no-account-img"><CgProfile /></div>
                                                    <div className="profile-camera">
                                                        <FiCamera />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </ImageUploading>
                            </div>
                        </div>
                        <div className='w-[100%]'>
                            <Input
                                label="Name *"
                                placeholder="Enter Profile name"
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={handleInputChange}
                            />
                            {errorMessage == '"name" is not allowed to be empty' && <ErrorText show message="This field is required " />}
                        </div>
                        <div className='w-[100%]'>
                            <Input
                                label="Shop Name *"
                                placeholder="Enter Profile Shop Name"
                                type="text"
                                id="shopName"
                                name="shopName"
                                value={shopName}
                                onChange={handleInputChange}
                            />
                            {errorMessage == '"shop_name" is not allowed to be empty' && <ErrorText show message="This field is required " />}
                        </div>
                        <div className='w-[100%]'>
                            <Input
                                label="Phone Number *"
                                placeholder="Enter Profile Phone Number"
                                type="tel"
                                name="phoneNo"
                                value={phoneNo}
                                onChange={handleInputChange}
                            />
                            {(errorMessage == '"phone_no" is not allowed to be empty' || errorMessage == "Phone number already exists. Please try with a different phone number.") && <ErrorText show message={errorMessage} />}
                        </div>
                    </div>
                    <div className="denied-btns">
                        <button className="denied-btn" onClick={() => setOpenProfileModal(false)}>Cancel</button>
                        <button className="denied-changes" onClick={handleSaveBtn}>Save</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default AgencyProfile