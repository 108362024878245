import React from 'react'
import ReactImageUploading from "react-images-uploading";
import './AddYoursImage.scss'
import { image } from '../../../../app/utils/common/image';

const AddYoursImage = ({ handleUploadImg, imageUrl }) => {

    // Handle the image selection and update the URL
    const handleChange = (imageList) => {
        if (imageList.length > 0) {
            const selectedImage = imageList[0].file;
            handleUploadImg(selectedImage, 'templates');
        } else {
            console.error('No image selected for upload');
        }
    };

    return (
        <div>
            <ReactImageUploading
                multiple={false}
                value={[]}
                onChange={handleChange}
                allowNonImageType={true}
                maxFileSize={3000000}
                // onError={(e) => { setErrorModal(true) }}
                acceptType={['jpeg', 'jpg', 'png', 'svg']}
            >
                {({ onImageUpload }) => (
                    <div onClick={onImageUpload}>
                        <button
                            className='upload-image-button' style={{ paddingTop: "2px", width: "98%", height: "64vh" }}>
                            {imageUrl ? (
                                <img src={imageUrl} alt="Selected" className="image-preview" />
                            ) : (
                                <>
                                    <img src={image.IMG_UPLOAD} className="image_gallery_icon" alt='' />
                                    <span className="upload-image-text">Upload File</span>
                                    <span className="upload-image_subtext">Type: JPEG, JPG, PNG<br /> Size: Images Max 3MB</span>
                                    <span className="upload-image_subtext">Dimensions: Width: 1366 pixels <br /> Height: 768 pixels </span>
                                </>
                            )}
                        </button>
                    </div>
                )}
            </ReactImageUploading>
        </div>

    )
}

export default AddYoursImage