import React, { useState } from 'react'
import { Modal } from 'antd';
import Button from "../../atoms/Button/Button"
import { AdDetails } from '../../molecules/AdDetails/AdDetails';
import { TemplatePreview } from '../../molecules/TemplatePreview/TemplatePreview';
import AddYoursModalTemp from '../../molecules/AddYours/AddYoursModalTemp/AddYoursModalTemp';
import { CreateTemplateModal } from '../../molecules/CreateTemplate/CreateTemplateModal/CreateTemplateModal';
import "./Template.scss"
import { useDispatch, useSelector } from 'react-redux';
import { getProfileData, getUserProfile } from '../../../redux/Slices/agencyProfileSlice';
import { clearOrderDetails } from '../../../redux/Slices/templateSlice';
import { AddYoursError } from '../../molecules/AddYours/AddYoursError/AddYoursError';

export const Template = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showAdDetails, setShowAdDetails] = useState(false)
    const [location, setLocation] = useState('');
    const [createTemplateOpen, setCreateTemplateOpen] = useState(false)
    const [templateImageUrl, setTemplateImageUrl] = useState([]);
    const dispatch = useDispatch()
    const userData = useSelector(getProfileData);

    return (
        <div className='main'>
            <div className='main_header'>
                <div className='heading'>
                    <h1>POSTER</h1>
                </div>
                <div className='heading-buttons_main'>
                    <div className="heading-buttons">
                        <div>
                            <Button
                                buttonTitle="Add Yours"
                                // onClick={handleAddYoursBtnClick}
                                onClick={() => { setModalIsOpen(true); dispatch(clearOrderDetails()) }}
                                background="bg-[--themeColor]"
                                disabled={false}
                            />
                        </div>
                        {/* <div>
                            <Button
                                buttonTitle="Create Template"
                                // onClick={handleAddYoursBtnClick}
                                onClick={() => { setCreateTemplateOpen(true) }}
                                background="bg-[--themeColor]"
                                disabled={false}
                            />
                        </div> */}


                    </div>
                </div>

            </div>

            {modalIsOpen && userData.Id === 0? <AddYoursError setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}/>:<AddYoursModalTemp setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} /> }

            {/* {modalIsOpen && <AddYoursModalTemp setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} />} */}
            {createTemplateOpen && <CreateTemplateModal setCreateTemplateOpen={setCreateTemplateOpen} createTemplateOpen={createTemplateOpen} templateImageUrl={templateImageUrl} setTemplateImageUrl={setTemplateImageUrl} />}

            {/* Show above created template */}

            <div className="templatePreview">
                <div className="templatePreview_card">
                    <div className="templatePreview_content">
                        <TemplatePreview templateImageUrl={templateImageUrl} />
                    </div>
                </div>
            </div>

            {showAdDetails &&
                <Modal open={showAdDetails} onCancel={() => { setShowAdDetails(false) }}>
                    <AdDetails setLocation={setLocation} />
                </Modal>}
        </div>
    );
};
