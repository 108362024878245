import React from 'react'
import { Modal } from 'antd'
import { CreateTemplateModalDetails } from '../CreateTemplateModalDetails/CreateTemplateModalDetails'
import { image } from '../../../../app/utils/common/image'
import "./CreateTemplateModal.scss"

export const CreateTemplateModal = ({ setCreateTemplateOpen, createTemplateOpen, setTemplateImageUrl }) => {
    return (
        <>
            <Modal
            className='ant-modal-body'
                open={createTemplateOpen}
                onCancel={() => setCreateTemplateOpen(false)}
                footer={null}
                style={{height:"90vh"}}
                > 
                <div className="createTemplate-container">
                    <div className="save-header">
                        <div className='text-[--themeColor] text-[18px] font-bold ml-[48%]'>Create Template</div>
                        <button className="btn-modal close-button" aria-label="Close" onClick={() => setCreateTemplateOpen(false)}>
                            <img src={image.IMG_CLOSE} className="image_close" />
                        </button>
                    </div>

                    <div className='save-main-container'>
                        <CreateTemplateModalDetails setTemplateImageUrl={setTemplateImageUrl} setCreateTemplateOpen={setCreateTemplateOpen}/>
                    </div>
                    

                </div>
            </Modal>
        </>

    )
}
