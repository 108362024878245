import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAllProfile, getProfileData, setProfileData, setSelectedProfileId } from '../../../redux/Slices/agencyProfileSlice';
import { image } from '../../../app/utils/common/image';
import OutsideClickHandler from 'react-outside-click-handler';
import LogOutButton from '../../atoms/LogOutButton/LogOutButton';
import ProfileDropDown from '../../atoms/ProfileDropDown/ProfileDropDown';
import { MdKeyboardArrowDown } from "react-icons/md";
import './Navbar.scss';
import LandingPageNew from '../../LandingPageNew/LandingPageNew';

export const Navbar = () => {
    const { pathname } = useLocation();
    const [openProfileDrpDwn, setOpenProfileDrpDwn] = useState(false);
    const dispatch = useDispatch();
    const profileData = useSelector(getProfileData);
    // const agenciesProfile = useSelector(getAllProfile)
    const { data: agenciesProfile, selectedProfileId } = useSelector(getAllProfile);

    useEffect(() => {
        if (agenciesProfile?.length === 1) {
            const defaultProfile = agenciesProfile[0];
            dispatch(setSelectedProfileId(defaultProfile.id));
            dispatch(setProfileData({
                name: defaultProfile.name,
                id: defaultProfile.id,
                phone_no: defaultProfile.phone_no,
                shop_name: defaultProfile?.shop_name,
                profile_logo: defaultProfile?.profile_logo
            }));
        }
    }, [agenciesProfile, dispatch]);

    return (
        <>
            {pathname === '/landingPageNew' ? <></> :
                <div className='nav-Container'>
                    <div className='header'>
                        <img src={image.IMG_LOGO} alt="Logo" onClick={() => {}} />
                        <div className='nav-btn'>

                            <div className='navbar'>
                                {/* <a href='/template' className={`mb-2 ${pathname === '/template' ? "selectednav" : ""}`}  >
                            <span>Template</span>
                            </a> */}
                                {
                                    pathname === '/landingPage' ?
                                        <>
                                            <a style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} target='' href=''>
                                                Privacy & Terms
                                            </a>
                                            <a className='download-button' target='' href=''>
                                                Download AdSimpl
                                            </a>
                                        </>
                                        : <>
                                            <a href='/myAds' className={`mb-2 ${pathname === '/myAds' ? "selectednav" : ""}`}  >
                                                <span>My Ads</span>
                                            </a>
                                            <a href='/agencyProfile' className={`mb-2 ${pathname === '/agencyProfile' ? "selectednav" : ""}`}  >
                                                <span>Add Profile</span>
                                            </a>
                                            <div className={`mb-2 cursor-pointer flex items-center`} onClick={() => setOpenProfileDrpDwn(!openProfileDrpDwn)} >
                                                {/* <span>Profile</span> */}
                                                {agenciesProfile?.length === 0 || !profileData?.name ? (
                                                    <span>Profiles</span>
                                                ) : (
                                                    agenciesProfile.length === 1 ? <span>{agenciesProfile[0]?.name}</span> : <span>{profileData?.name}</span>
                                                )}
                                                <MdKeyboardArrowDown size={20} />
                                            </div>
                                            <LogOutButton />
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                    {openProfileDrpDwn && <OutsideClickHandler
                        onOutsideClick={() => {
                            setOpenProfileDrpDwn(false)
                        }}
                    >
                        <ProfileDropDown openProfileDrpDwn={openProfileDrpDwn} setOpenProfileDrpDwn={setOpenProfileDrpDwn} />
                    </OutsideClickHandler>}
                </div>
            }
        </>
    );
}

// export default Navbar;