function isConsecutiveDate(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return (d2 - d1) / (1000 * 60 * 60 * 24) === 1;
}
export function groupDates(data, numberOfScreen) {
    const result = [];
    let currentGroup = null;
    data.forEach((item, index) => {
        if (
            !item.displayIds ||
            item.displayIds.length === 0
        ) {
            // Skip invalid items without breaking the group
            return;
        }
        if (
            currentGroup &&
            JSON.stringify(currentGroup.display_ids) ===
            JSON.stringify(item.displayIds) &&
            isConsecutiveDate(currentGroup.end_date, item.date)
        ) {
            currentGroup.end_date = item.date;
            // currentGroup.display_quantity++;
        } else {
            if (currentGroup) {
                result.push(currentGroup);
            }
            currentGroup = {
                start_date: item.date,
                end_date: item.date,
                display_quantity: numberOfScreen,
                display_ids: item.displayIds,
            };
        }
    });
    if (currentGroup) {
        result.push(currentGroup);
    }
    return result;
}