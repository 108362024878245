import React from 'react'
import { image } from "../utils/common/image";
import Header from '../Header/Header';
import './HomeLandingPage.scss'
const HomeLandingPage = () => {
    return (
        <div className='HomeLandingPage_main'>
            <div className='HomeLandingPage_main_subContainer'>
                <div className='Header_Main_main'>
                    <div className='Header_Main'>
                        <Header />
                    </div>
                    <div className='Header_Main_content'>
                        <h1 className='Header_Main_content-title'>
                            Revolutionizing <br />  Enterprises Digitally
                        </h1>
                        <p className='Header_Main_content-desc'>
                            Welcome to AdSimpl, we bring your advertisements to life—whether you're on mobile or web. Our platform makes it incredibly easy to create, manage, and display ads.
                        </p>
                    </div>
                    <div className='HomeLandingPage_background'>
                        <img src={image.HomeImage} alt="home image" />
                    </div>
                </div>
                <div className='phone_container'>
                    <div>
                        <img className='phoneImage1' src={image.PhoneImage} alt="phone image" />
                    </div>
                    <div>
                        <img className='phoneImage' src={image.PhoneImage} alt="phone image" />
                    </div>
                    <div>
                        <img className='phoneImage1' src={image.PhoneImage} alt="phone image" />
                    </div>
                </div>
                <div className='socialMedia'>
                    <div>
                        <img src={image.Facebook} />
                    </div>
                    <div>
                        <img src={image.Instagram} />
                    </div>
                    <div>
                        <img src={image.Twitter} />
                    </div>
                    <div>
                        <img src={image.Linkdin} />
                    </div>
                </div>
            </div>


        </div>
    )
}

export default HomeLandingPage
