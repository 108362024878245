import { Modal } from 'antd'
import React from 'react'
import "./AddYoursError.scss"
import { image } from '../../../../app/utils/common/image'
import { useNavigate } from 'react-router-dom'

export const AddYoursError = ({ setModalIsOpen, modalIsOpen }) => {
    const navigate = useNavigate();

    const handleGotoPrfileClick = () => {
        setModalIsOpen(false);
        navigate("/agencyProfile");
    }
    return (
        <>
            <Modal
                open={modalIsOpen}
                onCancel={() => setModalIsOpen(false)}
                footer={null}>
                <div className="Error-container">
                    <div className="Error-header">
                        <div className='text-[--themeColor] text-[18px] font-bold ml-[45%]'>ALERT</div>
                        <button className="btn-modal close-button" aria-label="Close" onClick={() => setModalIsOpen(false)}>
                            <img src={image.IMG_CLOSE} className="image_close" />
                        </button>
                    </div>
                    <div className='Error-main-container'>
                        <h1 className='error-text'> Oops! You haven't created or selected a profile yet, <br />
                            Please create a profile to access this feature.
                        </h1>
                        <div className='gotoProfile' onClick={handleGotoPrfileClick}> <u>GO TO PROFILE</u>  </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
