import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile, getCategories } from '../../../redux/Slices/templateSlice';
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Input from "../../atoms/Input/Input"
import "./AdDetails.scss"

export const AdDetails = ({ radius, setRadius, selectedCategories, setSelectedCategories, setLocation }) => {
    const [image, setImage] = useState(null)
    const [shopName, setShopName] = useState("");
    const [address, setAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [getUploadedLogoName, SetUploadedLogoName] = useState("");
    const dispatch = useDispatch();

    const categories = useSelector((state) => state.template.data);

    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    const categoriesOptions = categories?.categories?.map((item) => (
        {
            id: item.id,
            name: item.name
        }
    ))

    const handleShopNameChange = (event) => {
        setShopName(event.target.value);
    }

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    }
    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    }
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {

            const selectedImage = event.target.files[0];
            setImage(selectedImage);

            const formData = new FormData();
            formData.append('file', selectedImage);

            dispatch(uploadFile(formData)).then((action) => {
                if (action.type === "uploadFile/uploadFile/fulfilled") {
                    SetUploadedLogoName(action.payload.data.imageUrl);

                } else if (action.type === "uploadFile/uploadFile/rejected") {
                    console.error("File upload failed:", action.payload);
                }
            });
        }
    }

    const handleRadiusChange = (event) => {
        setRadius(event.target.value)
    }
    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    }
    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    }

    // To get latitude, longitude
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },

                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        } else {
            console.error('Geolocation not supported by your browser.');
        }
    }, []);


    return (
        <div >
            <div className='AdDetailsContainer'>
                <h1 className='text-[--themeColor]'>Ad Details</h1>
            </div>
            <div>
                <Input
                    label="Shop Name"
                    placeholder="Enter shop name"
                    type="text"
                    name="shopName"
                    value={shopName}
                    onChange={handleShopNameChange}
                />
                <Input
                    label="Address"
                    placeholder="Enter Address"
                    type="text"
                    name="address"
                    value={address}
                    onChange={handleAddressChange}
                />
                <div className='DivedeInput'>
                    <Input
                        label="City"
                        placeholder="Enter City"
                        type="text"
                        name="city"
                    // value={city}
                    // onChange={handleCityChange}

                    />

                    <Input
                        label="State"
                        placeholder="Enter State"
                        type="text"
                        name="state"
                    // value={state}
                    // onChange={handleStateChange}

                    />
                </div>

                <div className='DivedeInput'>
                    <Input
                        label="Country"
                        placeholder="Enter Country Name"
                        type="text"
                        name="country"
                    // value={city}
                    // onChange={handleCityChange}

                    />

                    <Input
                        label="Pincode"
                        placeholder="Enter Pincode"
                        type="text"
                        name="pincode"
                    // value={state}
                    // onChange={handleStateChange}

                    />
                </div>

                <Input
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                    type="tel"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                />

                <Input
                    label="Radius"
                    placeholder="Enter Radius (in KM)"
                    type="number"
                    name="radius"
                    value={radius}
                    onChange={handleRadiusChange}
                />


                <label className='multiSelectLabel' >
                    Select Category
                </label>
                <MultiSelect
                    value={selectedCategories}
                    onChange={(e) => setSelectedCategories(e.value)}
                    options={categoriesOptions}
                    display="chip"
                    optionLabel="name"
                    placeholder="Select Category"
                    // maxSelectedLabels={5}
                    className="multiselect"
                    required
                />
                <Input
                    label="Upload Shop Logo"
                    placeholder="Add shop Logo"
                    type="file"
                    name="shopLogo"
                    // value={image} 
                    onChange={onImageChange}
                />

                <Input
                    label="Start Date"
                    placeholder="Enter Start Date"
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={handleStartDateChange}
                />

                <Input
                    label="End Date"
                    placeholder="Enter End Date"
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={handleEndDateChange}
                />
            </div>
        </div>
    )
}
