import React, { useEffect, useState } from 'react'
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import Input from '../../../atoms/Input/Input';
import SelectInput from '../../../atoms/SelectInput/SelectInput';
import ErrorText from '../../../atoms/ErrorText/ErrorText';
import moment from 'moment/moment';

const AddYoursScreenDetails = (props) => {
    const { noOfScreen, days, handleInputChange, handleSelectInput, screenData, startDate, fieldErrors, discountAmt, totalAmount } = props;
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [minDate, setMinDate] = useState("");

    const groupedArray = [];
    screenData?.display_data.forEach(item => {
        const existingItem = groupedArray.find(groupedItem =>
            groupedItem.latitude === item.latitude &&
            groupedItem.longitude === item.longitude
        );

        if (existingItem) {
            existingItem.count += 1;
        } else {
            groupedArray.push({
                latitude: item.latitude,
                longitude: item.longitude,
                count: 1,
                city: item.city,
                state: item.state,
                pin_code: item.pin_code
            });
        }
    });

    const mapCenter = screenData?.display_data?.length
        ? {
            lat: parseFloat(screenData.display_data[0].latitude),
            lng: parseFloat(screenData.display_data[0].longitude),
        }
        : { lat: 21.056, lng: 24.566 }

    useEffect(() => {
        const dtToday = moment();
        const formattedDate = dtToday.format("YYYY-MM-DD");
        setMinDate(formattedDate);
    }, []);


    return (
        <div className='flex flex-col gap-4 mt-[20px] mb-[20px]'>
            <Input
                label="Total Number of Available Screen "
                placeholder="Enter Number of Screen"
                type="number"
                id="noOfScreen"
                name="noOfScreen"
                value={screenData.totalDisplay}
            />

            <GoogleMap
                mapContainerStyle={{ width: "100%", height: "400px", borderRadius: "12px", marginTop: '5px' }}
                center={mapCenter}
                zoom={8}
            >
                {groupedArray.map((location, index) => {
                    const markerData = {
                        title: `${location.city}`,
                        content: (
                            <div style={{ maxWidth: '120px', textAlign: 'center' }}>
                                <h3 style={{ margin: '0', padding: '0', color: '#ff6347' }}>{location.city}</h3>
                                <p style={{ margin: '3px 0 0', fontSize: '14px' }}>
                                    {`This location has ${location.count} screens.`}
                                </p>
                            </div>
                        ),
                    };
                    return (
                        <>
                            <Marker
                                key={location.id}
                                position={{
                                    lat: parseFloat(location.latitude),
                                    lng: parseFloat(location.longitude),
                                }}
                                title={`screen:${location.count}`}
                                onClick={() => setSelectedMarker(index)}
                            />
                            {selectedMarker && (
                                <InfoWindow
                                    position={{
                                        lat: parseFloat(location.latitude),
                                        lng: parseFloat(location.longitude),
                                    }}
                                    onCloseClick={() => setSelectedMarker(null)}
                                >
                                    <div>{markerData.content}</div>
                                </InfoWindow>)}
                        </>
                    )
                })}
            </GoogleMap>
            <div>
                <Input
                    label="Number of Screen *"
                    placeholder="Enter Number of Screen"
                    type="number"
                    id="noOfScreen"
                    name="noOfScreen"
                    value={noOfScreen}
                    onChange={handleInputChange}
                />
                {fieldErrors.message === '"display_quantity" must be a number' && <ErrorText show message={fieldErrors.message} />}
            </div>
            <div>
                <SelectInput
                    label="Number of Days *"
                    placeholder="Select Number of Days"
                    selected={days}
                    type="discount"
                    id="discount"
                    name="discount"
                    manualOptions={[]}
                    value={days}
                    onChange={(value) => { handleSelectInput(value) }}
                />
                {fieldErrors.message === '"date_interval" must be a number' && <ErrorText show message="Please select the number of days" />}
            </div>

            <div>
                <Input
                    label="Start date *"
                    placeholder="Enter start date"
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    min={minDate}
                    onChange={handleInputChange}
                />
                {fieldErrors.message === '"start_date" is not allowed to be empty' && <ErrorText show message="This is required field" />}
            </div>
        </div>
    )
}

export default AddYoursScreenDetails