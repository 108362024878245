import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiConstants, BASE_URL } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Create an async thunk to fetch ads data from the API
export const fetchMyAds = createAsyncThunk("myads/fetchMyAds", async ({ user_id, page, limit } = {}) => {
  const response = await apiClient.get(`${BASE_URL}${ApiConstants.MYADS}`, {
    params: { user_id, page, limit }
  });
  return response.data.data;
});

export const fetchMyAdsById = createAsyncThunk("myadsbyId/fetchMyAdsById", async (id) => {
  try {
    const response = await apiClient.get(`${BASE_URL}${ApiConstants.MYADS_BYID}${id}`)
    return response.data.data;
  } catch (e) {
    console.log('error: ', e)
  }
});
const myAdsSlice = createSlice({
  name: "myads",
  initialState: {
    data: [],
    singleAd: {},
    status: "success",
    adStatus: 'sucess',
    error: null,
    pagination: {
      totalData: 0,
      currentPage: 1,
      pageSize: 10,
      totalPages: 1
    }
  },
  reducers: {
    resetMyAdsData(state) {
      state.data = [];
      state.singleAd = {};
      state.status = "success";
      state.adStatus = 'sucess';
      state.error = null;

    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchMyAds.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchMyAds.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchMyAds.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchMyAdsById.pending, (state, action) => {
        state.adStatus = "loading";
      })
      .addCase(fetchMyAdsById.fulfilled, (state, action) => {
        state.adStatus = "succeeded";
        state.singleAd = action.payload;
      })
      .addCase(fetchMyAdsById.rejected, (state, action) => {
        state.adStatus = "failed";
        state.error = action.error.message;
      });

  },
});

export const { resetMyAdsData } = myAdsSlice.actions;
export const selectMyAdsPagination = (state) => state.data?.pagination;
export const selectMyAdsData = (state) => state.myads.data;
export const selectMyAdsStatus = (state) => state.myads?.status;
export const selectMyAdsByIdStatus = (state) => state.myads?.adStatus;


export default myAdsSlice.reducer;
