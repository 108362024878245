import React from 'react'
import { image } from "../../../app/utils/common/image";
import './LandingPage.css'
import { Button } from 'antd/lib';
const LandingPage = () => {
    const ContentBlock = ({ imgSrc, title, children, style }) => (
        <div className='homeContent-container'>
            {imgSrc && <img src={imgSrc} className='ss-image' />}
            <div className='homeContent' style={style}>
                <span>{title}</span>
                {children}
            </div>
        </div>
    );

    return (
        <div className='homepage-container'>
            <div className='homeContent-container'>
                <img src={image.HOME} className='ss-image-cover' />
                <div className='homeContent'>
                    <span>AdSimpl: </span>
                    Welcome to AdSimpl, we bring your advertisements to life—whether you're on mobile or web. Our platform makes it incredibly easy to create, manage, and display ads seamlessly across multiple devices. With AdSimpl, you’re not just advertising; you're connecting with the digital world in a powerful and innovative way. <br></br>
                    <b>Advertising is the art of connecting your message to the right audience, at the right time.</b>
                </div>
            </div>
            <ContentBlock title="**Get Started With App : ">
                With AdSimpl, creating and scheduling ads is easier than ever! Tailor your ads to fit your unique needs, whether it’s a promotional banner or something completely custom. Choose your design, set your message, and decide exactly when and where your ad should appear. Flexibility is key, so you’re in full control of your ad campaigns—schedule them for any time, on any device, at your convenience
            </ContentBlock>

            <div className='homepage-flex-app'>
                <ContentBlock imgSrc={image.ADDETAILS} title="Ad Details: ">
                    Includes all necessary information such as address, contact details, category, and more for a comprehensive advertising experience.
                </ContentBlock>
                <ContentBlock imgSrc={image.SCHEDULE} title="Schedule Manager: ">
                    Users can easily schedule ads by selecting the start date, number of screens, time slots, and display radius.
                </ContentBlock>
                <ContentBlock imgSrc={image.PAYMENT} title="Payment: ">
                    Enjoy a seamless payment process and preview how your ad will look before finalizing your purchase.
                </ContentBlock>
                <ContentBlock imgSrc={image.MYADS} title="My Ads: ">
                    Easily view and manage your created ads here, complete with approval status.
                </ContentBlock>
            </div>


            <ContentBlock title="**Get Started With Agency : ">
                Agencies can easily add multiple users, letting each one create their own ads. This makes it super simple to manage ad campaigns. Plus, agencies can see all the ads made for each user, along with detailed information, so they can keep everything organized and running smoothly.
            </ContentBlock>

            <div className='homepage-flex'>
                <ContentBlock imgSrc={image.ADDPROFILE} title="Create Profile: ">
                    In the profile section, agencies can add multiple users along with their information, and then easily create ads for each user..
                </ContentBlock>

                <ContentBlock imgSrc={image.CREATEAD} title="Create Ads: ">
                    Agencies can create ads for specific users, with features to select the category, address, shop logo, and more for a fully customized ad experience.
                </ContentBlock>
            </div>
            <div className='homepage-flex'>
                <ContentBlock imgSrc={image.ADDETAILSDAYS} title="Create Ads: ">
                    Agencies can also select the screens for displaying ads, choose the number of days, and set the start date for a fully personalized ad campaign.
                </ContentBlock>
                <ContentBlock imgSrc={image.AGENCYPAY} title="Payments: ">
                    Easily manage payments with a smooth and secure process, allowing agencies to complete transactions and track payment history for each ad campaign.
                </ContentBlock>
            </div>
            <div className='homepage-flex'>
                <ContentBlock imgSrc={image.VIEWADS} title="My Ads: ">
                    View all your created ads along with their current status right here, making it easy to manage and track your campaigns.
                </ContentBlock>
            </div>

            <ContentBlock title="**Join the AdSimpl Community : " style={{ fontSize: "22px" }}>
                Step into a world where creativity meets innovation! By joining the AdSimpl community, you'll gain access to powerful tools that make creating, managing, and displaying ads a breeze. Whether you're an individual, a business, or an agency, AdSimpl provides the platform to showcase your message to the right audience, at the right time. Connect, grow, and make your mark in the digital world with us! <br />
                <b> Great advertising is about turning ideas into action—start your journey with AdSimpl today</b>
            </ContentBlock>
            <div>
                <ContentBlock title="**Download AdSimpl today** " style={{ fontSize: "22px" }}>


                </ContentBlock>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "12px" }}>
                    <a className='download-button' target='' href=''>
                        Download AdSimpl
                    </a>
                </div>
            </div>

        </div>
    );
};




export default LandingPage
