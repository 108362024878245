import React from 'react';
import "./Testimonials.scss";
import { image } from "../utils/common/image";

const testimonialsData = [
    {
        name: "John Doe",
        role: "Software Engineer",
        description: "Our company, we do ongoing research with our target audience. This includes 30-45 minute phone interviews. It was difficult to conduct the interview, really listen, and ask good follow up questions.",
        img: image.Profile,
    },
    {
        name: "Bravo",
        role: "Software Engineer",
        description: "Our company, we do ongoing research with our target audience. This includes 30-45 minute phone interviews. It was difficult to conduct the interview, really listen, and ask good follow up questions.",
        img: image.Profile,
    },
    {
        name: "Cham",
        role: "Software Engineer",
        description: "Our company, we do ongoing research with our target audience. This includes 30-45 minute phone interviews. It was difficult to conduct the interview, really listen, and ask good follow up questions.",
        img: image.Profile,
    },
    {
        name: "Russell Lee",
        role: "Software Engineer",
        description: "Our company, we do ongoing research with our target audience. This includes 30-45 minute phone interviews. It was difficult to conduct the interview, really listen, and ask good follow up questions.",
        img: image.Profile,
    },
];

const Testimonials = () => {
    return (
        <div className='testimonials'>
            <div className='testimonials_main'>
                <h1 className='testimonials_heading'>Testimonials</h1>
                <p className='testimonials_description'>Wisdom new and valley answer. Contented it so is discourse recommend.</p>
            </div>

            <div className='testimonialList'>
                {testimonialsData.map((testimonial, index) => (
                    <div key={index} className='testimonialList_box'>
                        <div className='testimonialContent'>
                            <div className='testimonialContent_description'>
                                <p>{testimonial.description}</p>
                            </div>
                            <div className='testimonialContent_profile'>
                                <div className='profile_Img'>
                                    <img src={testimonial.img} alt={testimonial.name} />
                                </div>
                                <div className='profile_details'>
                                    <h2>{testimonial.name}</h2>
                                    <p>{testimonial.role}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='finishIcon'>
                <img src={image.FinishIcon} alt="Finish Icon" />
            </div>
        </div>
    );
};

export default Testimonials;
