import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearToken, logout } from '../../../redux/Slices/authSlice';

import './LogOutButton.scss'
import { resetProfileData } from '../../../redux/Slices/agencyProfileSlice';
import { resetMyAdsData } from '../../../redux/Slices/myAdsSlice';

const LogOutButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onPressLogOut = async () => {
        // Clear local storage and Redux state
        localStorage.clear();
        dispatch(clearToken());
        dispatch(resetProfileData());
        dispatch(resetMyAdsData());

        try {
            await dispatch(logout()).unwrap();
        } catch (error) {
            console.error('Logout failed:', error);
        }

        // Navigate to login page
        navigate("/login");
    }
    return (
        <div>
            <div className='btns' >
                <button className='btn' onClick={onPressLogOut}>Log Out</button>
            </div>
        </div>
    )
}

export default LogOutButton
