import React from 'react'
import "./ContactUs.scss"
import { image } from "../utils/common/image"
const ContactUs = () => {
    return (
        <div className='ContactUs_main'>
            <div>
                <h1>Need help locating us?</h1>
            </div>
            <div className='button_container'>
                <button className='contactUs_Button'><h2>CONTACT US </h2> <img src={image.ContactUSArrow} /> </button>
            </div>
        </div>
    )
}

export default ContactUs
