import React, { useEffect, useState, useRef } from 'react'
import "./TemplatePreview.scss"
// import { fabric } from 'fabric';
import * as fabric from 'fabric'; // v6

export const TemplatePreview = ({ templateImageUrl }) => {
  // console.log('savedTemplateImage on templatePreview Page', savedTemplateImage)
  const [imageUrls, setImageUrls] = useState([]);


  // Get the URLs from localStorage when the component mounts


  // const templateUrls = JSON.parse(localStorage.getItem('savedTemplateImages')) || [];

  // useEffect(() => {
  //   if (templateImageUrl) {
  //     setImageUrls((prevUrls) => [...prevUrls, templateImageUrl]);
  //   }
  //   console.log('imageUrls', imageUrls)
  // }, [templateImageUrl]);


  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef(null);
  const savedTemplate = localStorage.getItem('savedTemplate');

  useEffect(() => {

    if (savedTemplate) {
      // If there is an existing fabric canvas instance, dispose of it
      if (fabricCanvasRef.current) {
        fabricCanvasRef.current.dispose();

        fabricCanvasRef.current = null;
      }


      const canvas = new fabric.Canvas(canvasRef.current, {
        width: 1200,
        height: 600,
        backgroundColor: 'yellow',
      });

      fabricCanvasRef.current = canvas;

      const jsonData = `{
        "objects":[
    {
        "cropX": 0,
        "cropY": 0,
        "type": "Image",
        "version": "6.3.0",
        "originX": "center",
        "originY": "center",
        "left": 213.7255,
        "top": 140.8097,
        "width": 1600,
        "height": 900,
        "fill": "rgb(0,0,0)",
        "stroke": null,
        "strokeWidth": 0,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeUniform": false,
        "strokeMiterLimit": 4,
        "scaleX": 0.253,
        "scaleY": 0.253,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "skewX": 0,
        "skewY": 0,
        "src": "https://storage.googleapis.com/download/storage/v1/b/digi-app-26577.appspot.com/o/createTemplate%2F50dc0523-2da8-4ec1-9e14-ab70e5ec7f67-selectedImageURl.png?generation=1725430436767135&alt=media",
        "crossOrigin": null,
        "filters": []
    },
    {
        "rx": 0,
        "ry": 0,
        "type": "Rect",
        "version": "6.3.0",
        "originX": "center",
        "originY": "center",
        "left": 624.8999,
        "top": 99.3605,
        "width": 50,
        "height": 50,
        "fill": "black",
        "stroke": "black",
        "strokeWidth": 1,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeUniform": false,
        "strokeMiterLimit": 4,
        "scaleX": 4.3084,
        "scaleY": 2.8115,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "skewX": 0,
        "skewY": 0
    },
    {
        "fontSize": 24,
        "fontWeight": "normal",
        "fontFamily": "arial black",
        "fontStyle": "normal",
        "lineHeight": 1.16,
        "text": "First Ad",
        "charSpacing": 0,
        "textAlign": "left",
        "styles": [],
        "pathStartOffset": 0,
        "pathSide": "left",
        "pathAlign": "baseline",
        "underline": false,
        "overline": false,
        "linethrough": false,
        "textBackgroundColor": "",
        "direction": "ltr",
        "type": "IText",
        "version": "6.3.0",
        "originX": "left",
        "originY": "top",
        "left": 573.6207,
        "top": 41.1129,
        "width": 103.2422,
        "height": 27.12,
        "fill": "#f4ecec",
        "stroke": null,
        "strokeWidth": 1,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeUniform": false,
        "strokeMiterLimit": 4,
        "scaleX": 1,
        "scaleY": 1,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "skewX": 0,
        "skewY": 0
    },
    {
        "radius": 50,
        "startAngle": 0,
        "endAngle": 360,
        "counterClockwise": false,
        "type": "Circle",
        "version": "6.3.0",
        "originX": "center",
        "originY": "top",
        "left": 635.552,
        "top": 214.2637,
        "width": 100,
        "height": 100,
        "fill": "#1f9c16",
        "stroke": "black",
        "strokeWidth": 1,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeUniform": false,
        "strokeMiterLimit": 4,
        "scaleX": 1.9509,
        "scaleY": 1,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "skewX": 0,
        "skewY": 0
    },
    {
        "fontSize": 24,
        "fontWeight": "normal",
        "fontFamily": "arial black",
        "fontStyle": "normal",
        "lineHeight": 1.16,
        "text": "50% Off",
        "charSpacing": 0,
        "textAlign": "left",
        "styles": [],
        "pathStartOffset": 0,
        "pathSide": "left",
        "pathAlign": "baseline",
        "underline": false,
        "overline": false,
        "linethrough": false,
        "textBackgroundColor": "",
        "direction": "ltr",
        "type": "IText",
        "version": "6.3.0",
        "originX": "left",
        "originY": "top",
        "left": 583.9874,
        "top": 247.5164,
        "width": 103.2891,
        "height": 27.12,
        "fill": "black",
        "stroke": null,
        "strokeWidth": 1,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeUniform": false,
        "strokeMiterLimit": 4,
        "scaleX": 1,
        "scaleY": 1,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "skewX": 0,
        "skewY": 0
    },
    {
        "type": "Triangle",
        "version": "6.3.0",
        "originX": "center",
        "originY": "center",
        "left": 627.6643,
        "top": 123.8323,
        "width": 50,
        "height": 60,
        "fill": "#515205",
        "stroke": null,
        "strokeWidth": 1,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeUniform": false,
        "strokeMiterLimit": 4,
        "scaleX": 2.3299,
        "scaleY": 1.1865,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "skewX": 0,
        "skewY": 0
    }
],
        "background": "pink",
        "width": "1500"

    }`;



      canvas.loadFromJSON(jsonData, () => {
        canvas.renderAll.bind(canvas);

      })
        .then((canvas) => canvas.requestRenderAll())
        .catch((error) => console.error('Failed to restore canvas state:', error));;


      // Cleanup function to dispose of the canvas when the component unmounts
      return () => {

        if (fabricCanvasRef.current) {
          fabricCanvasRef.current.dispose();

          fabricCanvasRef.current = null;
        }
      };
    }
  }, []);
  return (
    <div>
      <h2>Template Preview</h2>
      <canvas ref={canvasRef} />
    </div>
    // <div>
    //   {imageUrls.map((url, index) => (
    //     <img key={index} src={url} style={{ marginBottom: '10px' }} />
    //   ))}
    // </div>

  )
}
