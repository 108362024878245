import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { image } from "../../../app/utils/common/image"
import { fetchMyAdsById, selectMyAdsByIdStatus } from '../../../redux/Slices/myAdsSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../atoms/Loader/Loader';
import Button from '../../atoms/Button/Button';
import "./MyAdsDetails.scss"

export const MyAdsDetails = ({ setViewMyAdsModalIsOpen, viewMyAdsModalIsOpen, selectedMyAdId }) => {
    const [loadingAds, setLoadingAds] = useState(false)
    const MyAdsDetailsById = useSelector((state) => state.myads?.singleAd);
    const myAdsByIdStatus = useSelector(selectMyAdsByIdStatus);

    const dispatch = useDispatch();

    useEffect(() => {
        setCallbackforDispatch();
    }, []);

    const setCallbackforDispatch = useCallback(() => {
        if (selectedMyAdId) {
            dispatch(fetchMyAdsById(selectedMyAdId));
        }
    }, [selectedMyAdId])

    // My Ads status loading state
    useEffect(() => {
        if (myAdsByIdStatus === 'loading') {
            setLoadingAds(true);
        } else if (myAdsByIdStatus === 'succeeded') {
            setLoadingAds(false);
        }
    }, [myAdsByIdStatus]);

    return (
        <div>
            <Modal
                open={viewMyAdsModalIsOpen}
                className='ant-modal-body'
                onCancel={() => { setViewMyAdsModalIsOpen(false) }}
                footer={null}
            >
                <div className="modal-container">
                    <>
                        <div className="modal-header">
                            <div className='text-[--themeColor] text-[18px] font-bold ml-[35%]'>My Ads Details</div>
                            <button className="btn-modal close-button" aria-label="Close" onClick={() => setViewMyAdsModalIsOpen(false)}>
                                <img src={image.IMG_CLOSE} className="image_close" alt="Close" />
                            </button>
                        </div>
                        <div className='modal-main-container'>
                            {!loadingAds ? <div className='info-group-Main'>
                                <div className="info-group">
                                    {MyAdsDetailsById ? (
                                        <>
                                            <p><strong>Shop Name:</strong> {MyAdsDetailsById?.shop_name}</p>
                                            <p><strong>Phone Number:</strong> {MyAdsDetailsById?.phone_no}</p>
                                            <p><strong>Payment Status:</strong> {MyAdsDetailsById?.payment_status}</p>
                                            <p><strong>Address:</strong> {MyAdsDetailsById?.selected_city}, {MyAdsDetailsById?.selected_state}, {MyAdsDetailsById?.selected_pin_code}</p>
                                            <p><strong>Subscription Cost:</strong> {MyAdsDetailsById?.sub_cost}</p>
                                            <p><strong>Display Quantity:</strong> {MyAdsDetailsById?.additional_ads_details?.map((item) => { return item?.display_quantity })}</p>
                                            <p><strong>Frequency:</strong> {MyAdsDetailsById?.additional_ads_details?.map((item) => { return item?.frequency })}</p>
                                            <p><strong>Start Date:</strong> {MyAdsDetailsById?.additional_ads_details?.map((item) => { return item?.start_date })}</p>                                        </>
                                    ) : (
                                        <Loader paddingRight={'pr-[14%]'} />
                                    )}
                                </div>
                            </div> : <div className='max-h-[450px] flex items-center justify-center h-[450px]' >
                                <Loader paddingRight={'pr-[8%]'} />
                            </div>
                            }
                        </div>
                        <div className='footer-buttons'>
                            <Button buttonTitle="Close" onClick={() => setViewMyAdsModalIsOpen(false)} background="bg-[--themeColor]" className={'save-button'} />
                        </div></>

                </div>
            </Modal>
        </div>
    );
};
