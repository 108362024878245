import React, { useState } from 'react'
import { Dropdown, message, Space } from 'antd';
import { MyAdsDetails } from '../MyAdsDetails/MyAdsDetails.js'
import "./MyAdsTemplate.scss"

export const MyAdsTemplate = ({ loading, myAds }) => {
  const [MyAdsOpen, setMyAdsOpen] = useState(false)
  const [selectedMyAdId, setSelectedMyAdId] = useState(null);
  const [viewMyAdsModalIsOpen, setViewMyAdsModalIsOpen] = useState(false);

  const handleButtonClick = (e) => {
    message.info('Click on left button.');
  };

  const items = [
    {
      label: 'View Ad Details',
      key: '1',
    },
  ];

  const handleMenuClick = (id) => {
    setSelectedMyAdId(id);
    setViewMyAdsModalIsOpen(true);
  };

  const menuProps = (id) => ({
    items,
    onClick: () => handleMenuClick(id),
  });

  const handleAdClick = () => {
    setMyAdsOpen(true);
  }

  return (
    <>
      <div className="">
        {myAds.myAds?.length > 0 && (
          <div className="card">
            {myAds.myAds.map((ad, index) => (
              <div className='card_Ad' key={ad.id} onClick={handleAdClick}>
                <p className='cardDots'>
                  <Space wrap>
                    <Dropdown.Button menu={menuProps(ad.id)} onClick={handleButtonClick} className='myDropdownButton'>
                    </Dropdown.Button>
                  </Space>
                </p>
                <img src={ad.image} alt={ad.image_name} />
                <div className="status">
                  {ad.status === "PENDING"
                    ? <h1 className='status_Pending'>🟠PENDING</h1>
                    : ad.status === "APPROVE"
                      ? <h1 className='status_Approve'>🟢APPROVE</h1>
                      : ad.status === "REJECTED"
                        ? <h1 className='status_Rejected'>🔴REJECTED</h1>
                        : null}
                </div>
              </div>
            ))}
          </div>
        )}
        {viewMyAdsModalIsOpen ? <MyAdsDetails selectedMyAdId={selectedMyAdId} viewMyAdsModalIsOpen={viewMyAdsModalIsOpen} setViewMyAdsModalIsOpen={setViewMyAdsModalIsOpen} /> : null}
      </div>
    </>

  )
}
