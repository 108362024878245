import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { SignUpPage } from '../../components/organisms/SignUpPage/SignUpPage'
import { MyAds } from '../../components/organisms/MyAds/MyAds'
import { Navbar } from '../../components/molecules/Navbar/Navbar'
import LoginPage from '../../components/organisms/LoginPage/LoginPage'
import { Template } from '../../components/organisms/Template/Template'
import CreateAgencyProfile from '../../components/organisms/CreateAgencyProfile/CreateAgencyProfile'
import LandingPage from '../../components/organisms/LandingPage/LandingPage'
import LandingPageNew from '../../components/LandingPageNew/LandingPageNew'

const AppRoutes = () => {
    return (
        <div>
            <Navbar />
            <Routes>
                <Route path='/login' element={<LoginPage />} />
                <Route path='/signup' element={<SignUpPage />} />
                <Route path='/template' element={<Template />} />
                <Route path='/myAds' element={<MyAds />} />
                <Route path='/agencyProfile' element={<CreateAgencyProfile />} />
                <Route path='/' element={<Navigate to="/login" />} />
                <Route path='/landingPage' element={<LandingPage />} />
                <Route path='/landingPageNew' element={<LandingPageNew />} />

            </Routes>
        </div>
    )
}

export default AppRoutes
