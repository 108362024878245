import React from 'react'

const Button = ({ buttonTitle, onClick, background, disabled, className }) => {
    return (
        // <button className={`button ${background} ${disabled} ${className} text-white flex items-center rounded-[.375rem] gap-2 px-8 py-2 text-md cursor-pointer `} onClick={() => { onClick() }}>
        //     {/* <img src={image} alt='add' /> */}
        //     <span>{buttonTitle}</span>
        // </button>
        <button
            className={`button ${background} ${className} text-white flex items-center rounded-[.375rem] gap-2 px-8 py-2 text-md cursor-pointer ${disabled ? ' cursor-default opacity-50' : 'cursor-pointer'}`}
            onClick={!disabled ? onClick : undefined} 
            disabled={disabled} 
        >
            <span>{buttonTitle}</span>
        </button>
    )
}

export default Button
