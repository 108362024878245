import React, { useEffect, useState } from 'react'
import { message } from 'antd';
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { addUserProfile, getAllProfile, getUserProfile, selectProfileStatus } from '../../../redux/Slices/agencyProfileSlice';
import AgencyProfile from '../../molecules/AgencyCreateProfile/AgencyProfile/AgencyProfile'
import AgencyProfileDetail from '../../molecules/AgencyCreateProfile/AgencyProfileDetail/AgencyProfileDetail'
import Loader from '../../atoms/Loader/Loader';
import { Pagination } from '../../atoms/Pagination/Pagination.js';

const CreateAgencyProfile = () => {
    const [openProfileModal, setOpenProfileModal] = useState(false);
    const [name, setName] = useState('');
    const [shopName, setShopName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [profileLogo, setProfileLogo] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [profileDataAgency, setProfileDataAgency] = useState([]);
    const [pageSize] = useState(10);

    const dispatch = useDispatch();
    const agenciesProfile = useSelector(getAllProfile);
    const agencyStatus = useSelector(selectProfileStatus);

    useEffect(() => {
        if (agenciesProfile.data?.length > 0) {
            setProfileDataAgency(agenciesProfile.data);
        } else {
            setProfileDataAgency([]);
        }
    }, [agenciesProfile.data]);

    // AgencyStatus loading state
    useEffect(() => {
        if (agencyStatus === 'loading') {
            setLoading(true);
        } else if (agencyStatus === 'succeeded') {
            setLoading(false);
        }
    }, [agencyStatus]);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < agenciesProfile.pagination?.totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setErrorMessage('');
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'shopName':
                setShopName(value);
                break;
            case 'phoneNo':
                setPhoneNo(value);
                break;
            default:
                break;
        }
    };

    const handleAddProfile = async () => {
        setLoading(true);
        const userData = {
            name,
            shop_name: shopName,
            phone_no: phoneNo,
            country_code: "+91",
            ...(profileLogo && { profile_logo: profileLogo })
        };
        try {
            await dispatch(addUserProfile(userData)).unwrap();
            await dispatch(getUserProfile()).unwrap();
            setOpenProfileModal(false);
            setName('');
            setShopName('');
            setPhoneNo('');
            setProfileLogo('');
            setLoading(false);
            message.success('User profile created successfully.');
        } catch (error) {
            setErrorMessage(error.error.message);
        }
        setLoading(false);
    }

    useEffect(() => {
        dispatch(getUserProfile({ page: currentPage, pageSize })) // Dispatch Get Profile action
    }, [dispatch, currentPage, pageSize])

    return (
        <> {!loading ? <div className='grid grid-cols-2 gap-6 py-8 overflow-auto'>
            <button
                className='upload-image-button' style={{ width: "400px", height: "485px" }}
                onClick={() => { setOpenProfileModal(true) }}>
                {/* <img src={UPLOADICON} className="image_gallery_icon" alt='' /> */}
                <div className='upload-icon'><IoMdAdd size={45} /></div>
                <span className="upload-image-text" style={{ fontSize: "20px" }}>Add user Profile</span>
            </button>
            <AgencyProfileDetail profileDataAgency={profileDataAgency} />

            {openProfileModal && <AgencyProfile
                openProfileModal={openProfileModal}
                setOpenProfileModal={setOpenProfileModal}
                name={name}
                shopName={shopName}
                phoneNo={phoneNo}
                profileLogo={profileLogo}
                setProfileLogo={setProfileLogo}
                handleSaveBtn={handleAddProfile}
                handleInputChange={handleInputChange}
                errorMessage={errorMessage}
            />}
        </div> :
            <div className="flex items-center justify-center">
                <Loader height="h-[75vh]" paddingRight="pr-[3rem]" />
            </div>}

            {profileDataAgency.length > 0 && agenciesProfile.pagination?.totalPages >= 1 && (
                <Pagination
                    currentPage={agenciesProfile.pagination?.currentPage}
                    totalPages={agenciesProfile.pagination?.totalPages}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                />
            )}
        </>
    )
}

export default CreateAgencyProfile