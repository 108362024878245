import React, { useEffect, useState } from 'react'
import Input from '../../atoms/Input/Input';
import './TemplateSettingPanel.scss'

export const TemplateSettingPanel = ({ canvas }) => {
    const [selectedObject, setSelectedObject] = useState(null)
    const [panelPosition, setPanelPosition] = useState({ top: 0, left: 0 });

    const [width, setWidth] = useState("")
    const [height, setHeight] = useState("")
    const [diameter, setDiameter] = useState("")
    const [color, setColor] = useState("")

    useEffect(() => {
        if (canvas) {
            canvas.on("selection:created", (event) => {
                hadleObjectSelection(event.selected[0]);
            });

            canvas.on("selection:updated", (event) => {
                hadleObjectSelection(event.selected[0]);
            });

            canvas.on("selection:cleared", () => {
                setSelectedObject(null);
                clearSettings();
            });

            canvas.on("object:modified", (event) => {
                hadleObjectSelection(event.target);
            });

            canvas.on("object:scaled", (event) => {
                hadleObjectSelection(event.target);
            });
        }
    }, [canvas]);

    const hadleObjectSelection = (object) => {
        if (!object) return;
        setSelectedObject(object);
        updatePanelPosition(object);

        if (object.type === "rect") {
            setWidth(Math.round(object.width * object.scaleX));
            setHeight(Math.round(object.width * object.scaleY));
            setColor(object.fill);

            setDiameter("");
        } else if (object.type === "circle") {
            setDiameter(Math.round(object.radius * 2 * object.scaleX));
            setColor(object.fill);
            setWidth("");
            setHeight("");
        } else if (object.type === "triangle") {
            setWidth(Math.round(object.width * object.scaleX));
            setHeight(Math.round(object.height * object.scaleY));
            setColor(object.fill);
        }


    };
    const clearSettings = (settings) => {
        setWidth("");
        setHeight("");
        setDiameter("");
        setColor("");
    }

    const handleWidthChange = (e) => {
        const intValue = parseInt(e.target.value, 10);
        setWidth(intValue);
        if (selectedObject && selectedObject.type === "rect" && intValue >= 0) {
            selectedObject.set({ width: intValue / selectedObject.scaleX });
            canvas.requestRenderAll();
        }
    };

    const handleHeightChange = (e) => {
        const intValue = parseInt(e.target.value, 10);
        setHeight(intValue);
        if (selectedObject && selectedObject.type === "rect" && intValue >= 0) {
            selectedObject.set({ height: intValue / selectedObject.scaleY });
            canvas.requestRenderAll();
        }
    };

    const handleDiameterChange = (e) => {
        const intValue = parseInt(e.target.value, 10);
        setDiameter(intValue);
        if (selectedObject && selectedObject.type === "circle" && intValue >= 0) {
            selectedObject.set({ radius: intValue / 2 / selectedObject.scaleX });
            canvas.requestRenderAll();
        }
    };

    const handleColorChange = (e) => {
        const value = e.target.value;
        setColor(value);
        if (selectedObject) {
            selectedObject.set({ fill: value });
            canvas.renderAll();
        }

    }

    //to show setting panel on selected object
    const updatePanelPosition = (object) => {
        const boundingRect = object.getBoundingRect();
        setPanelPosition({
            top: boundingRect.top + boundingRect.height + 10, // Adjust the offset as needed
            left: boundingRect.left + boundingRect.width / 2 - 75, // Center the panel horizontally
        });
    };

    return (
        <div className='settings'
            style={{
                top: `${panelPosition.top}px`,
                left: `${panelPosition.left}px`,
                display: selectedObject ? 'block' : 'none'
            }}
        >

            {selectedObject && (
                <>
                    {/* selectedObject.type === "i-text" */}
                    {/* {selectedObject.type === "i-text" && (
                        <div>
                            <Input
                                label="Width"
                                value={width}
                                onChange={handleWidthChange}
                            />
                            <Input
                                label="Height"
                                value={height}
                                onChange={handleHeightChange}
                            />
                        </div>
                    )} */}
                    {selectedObject.type === "rect" && (
                        <div>
                            <Input
                                label="Width"
                                value={width}
                                onChange={handleWidthChange}
                            />
                            <Input
                                label="Height"
                                value={height}
                                onChange={handleHeightChange}
                            />
                        </div>
                    )}
                    {selectedObject.type === "circle" && (
                        <div>
                            <Input
                                label="Diameter"
                                value={diameter}
                                onChange={handleDiameterChange}
                            />
                        </div>
                    )}
                    {selectedObject.type === "triangle" && (
                        <div>
                            <Input
                                label="Width"
                                value={width}
                                onChange={handleWidthChange}
                            />
                            <Input
                                label="Height"
                                value={height}
                                onChange={handleHeightChange}
                            />
                        </div>
                    )}
                    <div>
                        <Input
                            // style={{ height: "150px" }}
                            classNameInput="input_color"
                            label="Color"
                            type="color"
                            value={color}
                            onChange={handleColorChange}
                        // dafault={color}
                        />
                    </div>
                </>
            )}




        </div>
    )
}