import React, { useState } from 'react';
import "./Faqs.scss";
import { image } from "../utils/common/image";

const faqData = [
    {
        question: "Chamber reached do he nothing be?",
        answer: "Our asked  point her she seems. New plenty she horses parish design you.",
    },
    {
        question: "Stuff sight equal of my woody?",
        answer: "Our asked  point her she seems. New plenty she horses parish design you.  ",
    },
    {
        question: "At by pleasure of children be?",
        answer: "Explained remainder continual intention day to but a view of a week.",
    },
    {
        question: "Amounted repeated as believed in confined?",
        answer: "Am wound worth one stopped paper.",
    },
    {
        question: "In am do giving to afford parish settle easily garret?",
        answer: "Betrayed yet admitted with assistance or suspicion the foreign determined.",
    },
];

const Faqs = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const toggleFaq = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className='faqs'>
            <div className='faqs_main'>
                <h1 className='ourClients_heading'>FAQ's</h1>
            </div>
            <div className='faqContainer'>
                {faqData.map((faq, index) => (
                    <div key={index} className='faq' onClick={() => toggleFaq(index)}>
                        <div className='faq-question' >
                            <span>{activeIndex === index ? <img className='cancel' src={image.CancelIcon} /> : <img className='add' src={image.AddIcon} />}</span>
                            <h3>{faq.question}</h3>
                        </div>
                        {activeIndex === index && (
                            <div className='faq-answer'>
                                <div className='faq-answer_box'>
                                    <p>{faq.answer}</p>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className='semiCircle'>
                <img src={image.SemiCircle} />
            </div>

            <div className='lineAnimation'>
                <img src={image.LineAnimation} />
            </div>
        </div>
    );
}

export default Faqs;
