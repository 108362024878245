import axios from 'axios';
import {  BASE_URL } from '../utils/apis';
import { store } from '../../redux/store'; // Import the store directly
import { refreshAccessToken } from '../../redux/Slices/authSlice';

const apiClient = axios.create({
    baseURL: BASE_URL,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

apiClient.interceptors.request.use(
    async config => {
        // if (config.url.includes(ApiConstants.UPLOADFILE) && !localStorage.getItem('access_token')) {
        //     return config;
        // }
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        //  else {    
        //     window.location.href = '/login';
        // }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return apiClient(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve, reject) => {
                store.dispatch(refreshAccessToken())
                    .then(({ payload }) => {
                        const { access_token } = payload.data;
                        localStorage.setItem('access_token', access_token);
                        apiClient.defaults.headers['Authorization'] = `Bearer ${access_token}`;
                        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
                        processQueue(null, access_token);
                        resolve(apiClient(originalRequest));
                    })
                    .catch(err => {
                        processQueue(err, null);
                        localStorage.clear(); // Clear local storage to remove tokens
                        window.location.href = '/login'; // Redirect to login page
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(error);
    }
);

export { apiClient };