import React, { useEffect, useState } from 'react'
import { fetchMyAds, selectMyAdsData, selectMyAdsStatus } from "../../../redux/Slices/myAdsSlice.js";
import { useDispatch, useSelector } from 'react-redux';
import { MyAdsTemplate } from '../../molecules/MyAdsTemplate/MyAdsTemplate.js';
import { getProfileData } from '../../../redux/Slices/agencyProfileSlice.js';
import { Pagination } from '../../atoms/Pagination/Pagination.js';
import Button from '../../atoms/Button/Button.js';
import { clearOrderDetails } from '../../../redux/Slices/templateSlice.js';
import { AddYoursError } from '../../molecules/AddYours/AddYoursError/AddYoursError.js';
import AddYoursModalTemp from '../../molecules/AddYours/AddYoursModalTemp/AddYoursModalTemp.js';
import Loader from '../../atoms/Loader/Loader.js';
import "./MyAds.scss";

export const MyAds = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const myAds = useSelector(selectMyAdsData);
  const myAdsStatus = useSelector(selectMyAdsStatus);

  //get Agency Profile Id.
  const profileData = useSelector(getProfileData);

  const ProfileId = profileData.id;
  const paginationData = myAds.pagination;

  useEffect(() => {
    if (ProfileId) {
      dispatch(fetchMyAds({ user_id: ProfileId, page: currentPage, limit: 9 }));
    }
  }, [dispatch, ProfileId, currentPage]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < paginationData.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // My Ads status loading state

  useEffect(() => {
    if (myAdsStatus === 'loading') {
      setLoading(true);
    } else if (myAdsStatus === 'succeeded') {
      setLoading(false);
    }
  }, [myAdsStatus]);

  return (

    <div className="card-main">
      <div className="AddYoursButton">
        <Button
          buttonTitle="Add Yours"
          // onClick={handleAddYoursBtnClick}
          onClick={() => { setModalIsOpen(true); dispatch(clearOrderDetails()) }}
          background="bg-[--themeColor]"
          disabled={false}
        />
      </div>
      {!loading ? <>{myAds.myAds?.length > 0 ? (
        <>
          <MyAdsTemplate setLoading={setLoading} loading={loading} myAds={myAds} myAdsStatus={myAdsStatus} />

          <div className="pagination">
            <Pagination
              currentPage={paginationData?.currentPage}
              totalPages={paginationData?.totalPages}
              onPreviousPage={handlePreviousPage}
              onNextPage={handleNextPage}
            />
          </div>
        </>) : <div className='no-ads-container'>Oops! There is no ads in this profile
        <div>Please add Ads in this profile</div> </div>} </> :
        <div className='max-h-[450px] flex items-center justify-center h-[450px]' >
          <Loader />
        </div>}

      {modalIsOpen && profileData.Id === 0 ?
        <AddYoursError setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} /> :
        <AddYoursModalTemp setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} />}

    </div>
  )
}

