export const ApiConstants = {
    SENDOTP: '/api/auth/login/sendotp',
    LOGIN: '/api/auth/login',
    RESENDOTP: '/api/auth/resendotp',
    REFRESHTOKEN: '/api/auth/refresh-accesstoken',
    REGISTER_OTP: '/api/auth/register/sendotp',
    REGISTER: '/api/auth/register',
    LOGOUT: '/api/auth/logout',
    MYADS: '/api/user/myAds',
    MYADS_BYID: '/api/user/ads/',
    UPLOADFILE: '/api/upload/',
    POSTADS: '/api/user/ads',
    GETCATEGORIES: '/api/common/categories',
    SCREENAVAILABLE: '/api/user/screen-available',
    DATEAVAILABLE: '/api/user/date-available',
    ADUSERPROFILE: '/api/agency/add/user-profile',
    GETUSERPROFILE: '/api/agency/user-profiles'
}