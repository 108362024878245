import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from "./Slices/authSlice";
import myAdsSlice from "./Slices/myAdsSlice";
import templateSlice from "./Slices/templateSlice";
import agencyProfileSlice from "./Slices/agencyProfileSlice";

const persistConfig = {
    key: 'root',
    storage,
}

const combineReducer = combineReducers({
    auth: authSlice,
    myads: myAdsSlice,
    template: templateSlice,
    agencyProfile: agencyProfileSlice
})
const persistedReducer = persistReducer(persistConfig, combineReducer)


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export const persistor = persistStore(store)