import React from 'react'
import { image } from '../../../../app/utils/common/image';
import './AgencyProfileDetail.scss'
import Input from '../../../atoms/Input/Input';


const AgencyProfileDetail = ({ profileDataAgency }) => {

    return (
        <>
            {profileDataAgency?.map((profile) => {
                return (
                    
                    <div key={profile.id}className='agency-profile-container'>
                        <img src={profile.profile_logo ? profile.profile_logo : image.IMG_DEFAULT_PROFILE} alt="profile-image" className="account-profile" />
                        <div className='profile-text'>
                            <div className='fname'> Name :</div>
                            <Input
                                type="text"
                                id="name"
                                name="name"
                                value={profile.name}
                                className="w-[100%]"
                            />
                        </div>
                        <div className='profile-text'>
                            <div className='fname'> Shop Name :</div>
                            <Input
                                type="text"
                                id="shopName"
                                name="shopName"
                                value={profile.shop_name}
                                className="w-[100%]"
                            />
                        </div>
                        <div className='profile-text'>
                            <div className='fname'> Phone Number :</div>
                            <Input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={profile.phone_no}
                                className="w-[100%]"
                            />
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default AgencyProfileDetail