import React from 'react'
import "./AboutUsLandingPage.scss"
import { image } from "../utils/common/image";
import Button from '../atoms/Button/Button';

const AboutUsLandingPage = () => {
    return (
        <div className='AboutUsLandingPage_parent'>

            <img src={image.BackgroundAnimation} className='backgroundImage' alt='background animation' />
            <div className='AboutUsLandingPage_main'>
                <div className='AboutUsLandingPage_container'>
                    <div className='lineAnimation'>
                        <img src={image.LineAnimation} />
                    </div>
                    <h1 className='aboutus_heading'>About us</h1>
                    <p className='aboutus_description'> Welcome to AdSimpl, we bring your advertisements to life—whether you're on mobile or web. Our platform makes it incredibly easy to create, manage, and display ads seamlessly across multiple devices. With AdSimpl, you’re not just advertising; you're connecting with the digital world in a powerful and innovative way.
                        Advertising is the art of connecting your message to the right audience, at the right time.
                    </p>
                </div>

                <div className='AboutUsLandingPage_container_1'>
                    <div className='aboutUsLeft'>
                        <div className='aboutUsLeft_Box'>
                            <div className='aboutUsLeft_Phone'>
                                <img src={image.PhoneImage} alt='phoneImage' />
                            </div>
                        </div>
                    </div>
                    <div className='aboutUsRight'>
                        <h1>Lorem ipsum dummy text ever Lorem ipsum dummy text ever
                        </h1>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <Button className='learnMoreButton' buttonTitle={"Learn More"} />
                    </div>
                </div>


                <div className='AboutUsLandingPage_container_2'>
                    <div className='aboutUsRight'>
                        <h1>Lorem ipsum dummy text ever Lorem ipsum dummy text ever
                        </h1>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <Button className='learnMoreButton' buttonTitle={"Learn More"} />
                    </div>
                    <div className='aboutUsLeft'>
                        <div className='aboutUsLeft_Box'>
                            <div className='aboutUsLeft_Phone'>
                                <img src={image.PhoneImage} alt='phoneImage' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='AboutUsLandingPage_container_3'>
                    <div className='aboutUsLeft'>
                        <div className='aboutUsLeft_Box'>
                            <div className='aboutUsLeft_Phone'>
                                <img src={image.PhoneImage} alt='phoneImage' />
                            </div>
                        </div>
                    </div>
                    <div className='aboutUsRight'>
                        <h1>Lorem ipsum dummy text ever Lorem ipsum dummy text ever
                        </h1>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <Button className='learnMoreButton' buttonTitle={"Learn More"} />
                    </div>
                </div>

                <div className='AboutUsLandingPage_container_2'>
                    <div className='aboutUsRight'>
                        <h1>Lorem ipsum dummy text ever Lorem ipsum dummy text ever
                        </h1>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <Button className='learnMoreButton' buttonTitle={"Learn More"} />
                    </div>
                    <div className='aboutUsLeft'>
                        <div className='aboutUsLeft_Box'>
                            <div className='aboutUsLeft_Phone'>
                                <img src={image.PhoneImage} alt='phoneImage' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='semiCircle'>
                <img src={image.SemiCircle} />
            </div>


        </div>
    )
}

export default AboutUsLandingPage
