import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiConstants, BASE_URL } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";


export const addUserProfile = createAsyncThunk('agencyProfile/addUserProfile', async (userData, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(`${BASE_URL}${ApiConstants.ADUSERPROFILE}`, userData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

export const getUserProfile = createAsyncThunk('agencyProfile/getUserProfile', async ({ page, pageSize = 10 } = {}, { rejectWithValue }) => {
  try {
    const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETUSERPROFILE}`, {
      params: {
        page,
        pageSize
      }
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
})

const agencyProfileSlice = createSlice({
  name: "agencyProfile",
  initialState: {
    data: [],
    status: "success",
    error: null,
    // dropDownData:[],
    profileData: {
      Id: 0,
      name: '',
      phone_no: '',
      shop_name: '',
      profile_logo: '',
    },
    pagination: {
      totalData: 0,
      currentPage: 1,
      pageSize: 10,
      totalPages: 1
    },
    selectedProfileId: null,
  },
  reducers: {
    setProfileData(state, action) {
      const { name, id, phone_no, shop_name, profile_logo } = action.payload;
      state.profileData = { name, id, phone_no, shop_name, profile_logo };
    },
    resetProfileData(state) {
      state.profileData = { Id: 0, name: '', phone_no: '', shop_name: '', profile_logo: '' };
      state.data = [];
      state.status = "success";
      state.error = null;
    },
    setCurrentPage(state, action) {
      state.pagination.currentPage = action.payload;
    },
    setSelectedProfileId(state, action) {
      state.selectedProfileId = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addUserProfile.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(addUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getUserProfile.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload?.data.agencies;
        state.pagination = action.payload?.data.pagination
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});


export const { setProfileData, resetProfileData, setCurrentPage, setSelectedProfileId } = agencyProfileSlice.actions;
export const getAllProfile = (state) => state?.agencyProfile || [];
export const getProfileData = (state) => state?.agencyProfile?.profileData;
export const selectProfileStatus = (state) => state.agencyProfile?.status;
export const selectProfileError = (state) => state.data?.error;
export const selectMyAdsPagination = (state) => state.data.pagination;

export default agencyProfileSlice.reducer;
