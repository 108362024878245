import React from 'react'
import Button from '../Button/Button';
import "./Pagination.scss";

export const Pagination = ({ currentPage, totalPages, onPreviousPage, onNextPage }) => {

    return (
        <div className="pagination">
            <Button
                buttonTitle="Previous"
                onClick={onPreviousPage}
                className="save-button"
                disabled={currentPage === 1}
            >
            </Button>

            <span className="pagination-info">
                Page {currentPage} of {totalPages}
            </span>

            <Button
                buttonTitle="Next"
                disabled={currentPage === totalPages}
                onClick={onNextPage}
                className="save-button"
            >
            </Button>
        </div>
    );
};

